var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"banner",class:{
    [_vm.color]: true,
  }},[(_vm.icon)?_c('div',{staticClass:"banner__icon",attrs:{"data-testid":"banner-icon"}},[_c('i',{staticClass:"icon icon-2x",class:_vm.icon})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"banner__content",class:{
      closable: _vm.closable,
      stacked: _vm.stacked,
      icon: _vm.icon
    },attrs:{"data-testid":"banner-content"}},[_vm._t("default",function(){return [(_vm.labelKey)?_c('t',{attrs:{"k":_vm.labelKey,"raw":true}}):(_vm.messageLabel)?_c('span',[_vm._v(_vm._s(_vm.messageLabel))]):_c('span',{directives:[{name:"clean-html",rawName:"v-clean-html",value:(_vm.nlToBr(_vm.label)),expression:"nlToBr(label)"}]})]}),_vm._v(" "),(_vm.closable)?_c('div',{staticClass:"banner__content__closer",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"icon icon-close closer-icon",attrs:{"data-testid":"banner-close"}})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }