var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",staticClass:"unlabeled-select",class:{
    disabled: _vm.disabled && !_vm.isView,
    focused: _vm.focused,
    [_vm.mode]: true,
    [_vm.status]: _vm.status,
    taggable: _vm.$attrs.taggable,
    taggable: _vm.$attrs.multiple,
  },on:{"focus":_vm.focusSearch}},[_c('v-select',_vm._g(_vm._b({ref:"select-input",staticClass:"inline",class:{'select-input-view': _vm.mode === 'view'},attrs:{"autoscroll":true,"append-to-body":_vm.appendToBody,"calculate-position":_vm.positionDropdown,"disabled":_vm.isView || _vm.disabled,"get-option-key":(opt) => _vm.getOptionKey(opt),"get-option-label":(opt) => _vm.getOptionLabel(opt),"label":_vm.optionLabel,"options":_vm.options,"close-on-select":_vm.closeOnSelect,"map-keydown":_vm.mappedKeys,"placeholder":_vm.placeholder,"reduce":(x) => _vm.reduce(x),"searchable":_vm.isSearchable,"selectable":_vm.selectable,"value":_vm.value != null ? _vm.value : ''},on:{"search:blur":_vm.onBlur,"search:focus":_vm.onFocus,"open":_vm.resizeHandler,"option:created":(e) => _vm.$emit('createdListItem', e)},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{on:{"mousedown":(e) => _vm.onClickOption(option, e)}},[_vm._v("\n        "+_vm._s(option.label)+"\n      ")])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners)),_vm._v(" "),(_vm.tooltip && !_vm.focused)?_c('LabeledTooltip',{attrs:{"hover":_vm.hoverTooltip,"value":_vm.tooltip,"status":_vm.status}}):_vm._e(),_vm._v(" "),(!!_vm.validationMessage)?_c('LabeledTooltip',{attrs:{"hover":_vm.hoverTooltip,"value":_vm.validationMessage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }