<script>

export default {
  props: {
    value: {
      type:    String,
      default: ''
    },
    row: {
      type:     Object,
      required: true
    },
    col: {
      type:    Object,
      default: () => {}
    },
  },
};
</script>

<template>
  <span>
    {{ value }}%
  </span>
</template>
