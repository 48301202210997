<script>
export default { middleware: ['unauthenticated'] };
</script>

<template>
  <nuxt />
</template>

<style lang="scss">
body, #__nuxt, #__layout {
  height: 100%;
}
</style>
