
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: {
      type:    [Boolean, String, Number],
      default: false
    },

    offValue: {
      type:    [Boolean, String, Number],
      default: false,
    },

    onValue: {
      type:    [Boolean, String, Number],
      default: true,
    },

    offLabel: {
      type:    String,
      default: '',
    },

    onLabel: {
      type:    String,
      default: '',
    },
  },
  data() {
    return { state: false as boolean | string | number };
  },

  watch: {
    value: {
      handler() {
        this.state = this.value === this.onValue;
      },
      immediate: true
    }
  },

  methods: {
    toggle(neu: boolean | string | number) {
      this.state = neu === null ? !this.state : neu;
      this.$emit('input', this.state ? this.onValue : this.offValue);
    }
  }
});
